<nav class="blue darken-2 z-depth-3 nav2">
    <div class="nav-wrapper">
        <a href="#" class="brand-logo center">
            <img src="assets/logo.png" alt="logo" class="hide-on-med-and-down logo1">
            <img src="assets/logo-responsive.png" alt="logo" class="hide-on-large-only logo2">
        </a> 
    </div>
</nav>

<nav  class="blue darken-1 z-depth-3">
    <div class="nav-wrapper">
      <ul id="nav-mobile" class="hide-on-med-and-down brand-logo center" style=" width: 60%; ">
        <li>
            <a routerLink="/" class="waves-effect waves-light">Home</a>
        </li>
        <li>
            <a routerLink="/liste/articles" class="waves-effect waves-light">Articles</a>  
        </li>
        <li>
            <a routerLink="/privacy-policy" class="waves-effect waves-light">Privacy Policy</a>
        </li> 
        <li>
            <a routerLink="/terms-and-conditions" class="waves-effect waves-light">Terms And Conditions</a>
        </li> 
        <li>
            <a routerLink="/GDPR-privacy-notice" class="waves-effect waves-light">GDPR Privacy Notice</a>
        </li> 
        <li>
            <a routerLink="/contact" class="waves-effect waves-light">Contact Us</a>
        </li>
      </ul>
      
      <div class="left menu hide-on-large-only" (click)="slide()">
            <img src="assets/menu.png" alt="menu">
      </div>

    </div>
</nav>

