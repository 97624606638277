import {Component} from '@angular/core';
import $ from 'jquery';
import { environment } from "../../environments/environment.prod";
import { Router } from '@angular/router';
import { SearchComponent } from '../search/search.component';
import { SearchService } from '../search/search.service';
 
@Component({
  selector: 'search',
  template: `
      <div class="col s12 m2 l2"></div>
      <div class="col s12 m8 l8">
        <div class="row">
          <div class="col s12 m8 l8">
            <div class="input-field">
              <input #searchfield type="text" id="search" class="validate activator">
              <label for="search" class="active">Recherche</label>
            </div>
          </div>
          <div class="col s12 m4 l4">
            <button class="blue darken-1 btn" (click)="search(searchfield.value)" style="position: relative;top: 25px;">Envoyer</button>
          </div>
        </div>
      </div>
      <div class="col s12 m2 l2"></div>    
  `,
  styles: [`
    .pointer {
      cursor: pointer
    }
  `],
})

export class HomeSearchComponent {
  public data = [];
  
  constructor(public router: Router,public service: SearchService) { }

  search(data){
      environment["search"] = data;
      this.router.navigateByUrl('/search');
      if(this.router.url == "/search") {
        this.router.navigateByUrl('/search/reload'); 
      }
      if(this.router.url == "/search/reload") {
        this.router.navigateByUrl('/search'); 
      }
  }

}