<div style="text-align:center;position: absolute;top: 50%;transform: translateY(-50%);margin: 0 auto;right: 0;left: 0;" *ngIf="!loader">
    <loader ></loader>
</div>
<div *ngIf="loader">

    <div id="menu">
            <div style="height:30%;width:100%;" class="z-depth-3 blue darken-3"></div>
            <menu></menu>    
    </div>
    <section id="panel" style="background-color:white">
        <navbar ></navbar>
        <router-outlet></router-outlet>

        <Footer></Footer>
    </section>

</div>