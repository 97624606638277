import { Component, OnInit } from '@angular/core';
import $ from 'jquery';
import Materialize from 'Materialize-css';

@Component({
  selector: 'Footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public url="";
  constructor() { }

  ngOnInit() {
    
  }
  
}
