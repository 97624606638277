import { Component, OnInit } from '@angular/core';
import { environment } from "../../environments/environment.prod";
import { SearchService } from './search.service';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  public loader : boolean = false;
  public search;
  public news;
  public allproducts;
  public productsv;
  public products4;
  public i=0;
  public products20;
  public active = [true];
  constructor(public router: Router,private service: SearchService, private titleService: Title) { }
 
  ngOnInit() {
    this.titleService.setTitle("BTN - Recherche");
    window.scrollTo(0,0);
    if(this.router.url == "/search/reload") {
      this.router.navigateByUrl('/search'); 
    }
      this.loader = false;
      if(environment["search"]){
        this.search = environment["search"];
        this.news = environment["articles"].filter(
          e => e["title"].indexOf(this.search)!=-1 || e["message"].indexOf(this.search)!=-1 
        );
        this.loader = true; 
        /*this.service.search(this.search).subscribe(
          res => {
            this.news = res.json()
            this.loader = true; 
          },
          err => console.log(err)
        )*/
        
      } else {
        this.loader = true; 
        this.router.navigateByUrl('/'); 
      }
  }

}
