<div class="section pointer" routerLink="/">
    Homr
</div>
<div class="section pointer" routerLink="/liste/articles">
    Articles
</div>
<div class="section pointer" routerLink="/privacy-policy">
    Privacy Policy
</div>  
<div class="section pointer" routerLink="/terms-and-conditions">
    Terms And Conditions
</div>    
<div class="section pointer" routerLink="/GDPR-privacy-notice">
    GDPR Privacy Notice
</div>    
<div class="section pointer" routerLink="/contact">
    Contact Us
</div>    
 
