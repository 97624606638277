import { Component, OnInit } from '@angular/core';
import Slideout from 'slideout';
import $ from 'jquery';
import { environment } from "../../environments/environment.prod";

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  slideout
  constructor() { }

  ngOnInit() {
    this.slideout = new Slideout({
      'panel': document.getElementById('panel'),
      'menu': document.getElementById('menu'),
      'padding': 256,
      'tolerance': 70
    });
  }

  slide(){
    this.slideout.toggle();
  }

}
