<div>
  <div style="text-align:center" *ngIf="!loader">
      <loader ></loader>
  </div>
  <div *ngIf="loader">
        <div class="row">
          <div class="col xl1 l1 m1 s12"></div>
          <div class="col xl10 l10 m10 s12">
            <div class="row">
                <br>
                <br>
                  <search></search> 
                <br>
                <br>  
            </div> 
            <ul class="row" >
                    <li *ngFor="let article of news;index as i" >
                      <span class="col s12 m6 l3">
                        <article [item]="article" [length]="55" ></article>
                      </span>
                    </li>            
            </ul> 
            <div class="row center"> 
              <!--
              <a class="waves-effect waves-light btn blue darken-2 {{btndisable}} " (click)="addNews()">
                AFFICHER PLUS 
              </a>-->
              <br>
              <br>
              <div class="preloader-wrapper small active" *ngIf="btndisable == 'disabled'">
                  <div class="spinner-layer spinner-blue-only">
                    <div class="circle-clipper left">
                      <div class="circle"></div>
                    </div><div class="gap-patch">
                      <div class="circle"></div>
                    </div><div class="circle-clipper right">
                      <div class="circle"></div>
                    </div>
                  </div>
              </div>
            </div>

          </div>

          <div class="col xl1 l1 m1 s12"></div>
          
        </div>
        
  </div>
</div>

