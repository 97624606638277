import {Component, Input, OnInit, AfterViewInit, ElementRef} from '@angular/core';
import { DetailService } from '../detail/detail.service';

@Component({
  selector: 'article',
  template: `
    <div class="card hoverable card-height">
      <a  routerLink="{{getUrl(article)}}">      
        <div class="card-image">
          <img class="article-img" id="article-img-{{article.i}}" src="assets/blank.png" [attr.src]="getImg(article)" alt="article-img">
        </div>
      </a>
      <div class="card-content">
          <a  routerLink="{{getUrl(article)}}">
            <div id="title{{i}}" dir="auto" class="center" style="text-transform: none;color: #212121;">
              {{setTitle(article.title)}}
            </div>
          </a>

          <!--<div class="row pircing center-align" style="margin : auto">
              <div class="col s12 center">
                  <b> {{article.articlesname}} </b>
              </div>
          </div>-->
      </div>
    </div>
  `,
  styles: [`
    .pointer {
      cursor: pointer
    }
    .rating img{
        width: 16px;
    }
    .article-img{
        width : 100%;
        max-height:150px   
    }
    .price {
        color: red
    }
    .card-height{
        height: 300px
    }
    .menu{
      position: absolute;
      bottom: 30px;
      right : 0;
      left : 0;
    }
    .pircing{
      position: absolute;
      bottom: 10px;
      right : 0;
      left : 0;
    }
  `],
})
export class HomeArticleComponent implements OnInit,AfterViewInit {
  @Input() item : object;
  @Input() length : string;
  @Input() wishlist : boolean;
  public i;
  public article;
  constructor(private service : DetailService,private elementRef:ElementRef) { }

  ngAfterViewInit() {
    var img = this.elementRef.nativeElement.querySelector('img');
    img.addEventListener("load", () =>{
      //var img = this.elementRef.nativeElement.querySelector('img');
        if((img.src).indexOf("i.ytimg.com") != -1){
          if(img.naturalHeight == 90 && img.naturalWidth == 120 ){
            img.src = (img.src).replace("maxresdefault","hqdefault"); 
          }
        } else {
          if(!img.naturalHeight && !img.naturalWidth){
            img.src =  'assets/blank.png';
          }
        } 
    });
    img.addEventListener("error", () =>{
      img.src =  'assets/blank.png';
    });
      /*setTimeout(() => {
        var img = this.elementRef.nativeElement.querySelector('img');
        if((img.src).indexOf("i.ytimg.com") != -1){
          if(img.naturalHeight == 90 && img.naturalWidth == 120 ){
            img.src = (img.src).replace("maxresdefault","hqdefault"); 
          }
        } else {
          if(!img.naturalHeight && !img.naturalWidth){
            img.src =  'assets/blank.png';
          }
        }  
      }, 2000);*/
  }
  
  ngOnInit() {
    this.article = this.item;
  }
  
  setTitle(title){  
    var length = (this.length) ? (( this.length == "55" ) ? 55 : 75) : 75;
    if(title.length > length){
      title = (title.slice(0, length)) + "..."
    }
    return title;
  }

  getImg(article){
    return article.url; 
  }

  getUrl(article){
    if((article.articlesurl)){
      if((article.articlesurl).indexOf("youtu.be") == -1){
        return "/articles/"+article.i+"/detail";
      } else {
        return "/videos/"+article.i+"/detail";
      }
    } else {
      return "/articles/"+article.i+"/detail";
    } 
  }
  
  /*test(msg){
    console.log(msg)
  }*/
  
}