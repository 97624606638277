import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

//my costum module
import { AppRoutingModule } from './app-routing.module';

//components
import { AppComponent } from './app.component';
import { MenuComponent } from './menu/menu.component';
import { LoaderComponent } from './loader/loader.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';

//home page
import { HomeComponent } from './home/home.component';
import { HomeSearchComponent } from './home/home.search.component';
import { HomeArticleComponent } from './home/home.article.component';

//contact page
import { ContactComponent } from './contact/contact.component';

//list of product page
import { ListComponent } from './list/list.component';

//other page
import { DetailComponent } from './detail/detail.component';

//services
import { AppService } from './app.service';
import { HomeService } from './home/home.service';
import { ListService } from './list/list.service';
import { DetailService } from './detail/detail.service';
import { ContactService } from './contact/contact.service';
import { SearchComponent } from './search/search.component';
import { SearchService } from './search/search.service';
import { AddThisService } from './addthis.service';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { GdprComponent } from './gdpr/gdpr.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavbarComponent,
    LoaderComponent,
    MenuComponent,
    HomeSearchComponent,
    HomeArticleComponent,
    FooterComponent,
    ContactComponent,
    ListComponent,
    DetailComponent,
    SearchComponent,
    PrivacyPolicyComponent,
    TermsAndConditionsComponent,
    GdprComponent 
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule
  ],
  providers: [
    AddThisService, 
    AppService,
    HomeService,
    ListService,
    NavbarComponent,
    DetailService,
    ContactService,
    SearchService 
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }