<div >
  <div style="text-align:center" *ngIf="!loader">
      <loader ></loader>
  </div>
  <div *ngIf="loader">
    <div class="col xl1 l1 m1 s12"></div>
    <div class="col xl10 l10 m10 s12">
      <div class="card" *ngIf="news"> 
          <div class="card-content">
              <div class="card-title">
                  Resultat de la recherche : {{search}}
              </div>
              <ul class="row" >
                  <li *ngFor="let article of news;index as i" >
                    <span class="col s12 m6 l3">
                      <article [item]="article" [length]="55" ></article>
                    </span>
                  </li>            
              </ul> 
          </div>
      </div>
    </div>
    <div class="col xl1 l1 m1 s12"></div>
    <br>
    <br>
  </div>
</div>

