import { Component, OnInit, AfterViewInit } from '@angular/core';
import { environment } from "../../environments/environment.prod";
import { DetailService } from './detail.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AddThisService } from '../addthis.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit,AfterViewInit {

  public loader : boolean = false;
  public id;
  public news;
  public category="articles";
  public img;
  public description;
  public iframeMouseOver;
  constructor(private route: ActivatedRoute,private router: Router, private titleService: Title,private service : DetailService,private _addThis : AddThisService) { }
 
  ngOnInit() {  
    this.titleService.setTitle("CookSox  - Detail");
    window.scrollTo(0,0);
    this.route.params.subscribe(params => {  
          for (const i in environment[params.category]) {
            this.category = params.category;
            if(environment[params.category][i].i == params.id){
              this.news = environment[params.category][i];
              this.titleService.setTitle(this.news.title + " - CookSox ");
              this.treatMessage();
            }
          }
          this.service.select(params.category,params.id).subscribe(
            res => {
              this.news = (res)[0];
              console.log("this.news");
              console.log(this.news);
              if(this.news){
                this.treatMessage();
                this.titleService.setTitle(this.news.title + " - CookSox ");
              } else {
                this.router.navigateByUrl("")
              }
            },
            err => console.log(err)
          )
          //this.router.navigateByUrl("liste/"+params.category)
    })
  }
  ngAfterViewInit(){
    
    this._addThis.initAddThis('ra-5b64d7d77892c18b', false).subscribe();
    /*if(document.getElementById('description')) 
      document.getElementById('description').innerHTML = this.news.message; 
    else 
    if(document.getElementById('source')) 
      document.getElementById("source")["src"]="https://www.youtube-nocookie.com/embed/"+((this.news.articlesurl).replace("https://youtu.be/",""))+"?controls=0&autoplay=0";
    */
  }

  gotoSource(){
    window.open("https://dzairactus.com/articles/"+this.news.i+"/source","_blank");
  }

  clickVideo(){
    if((document.getElementById("source")["src"]).indexOf("autoplay=1")!=-1)
      document.getElementById("source")["src"]="https://www.youtube-nocookie.com/embed/"+((this.news.articlesurl).replace("https://youtu.be/",""))+"?controls=0&autoplay=0";
    else if((document.getElementById("source")["src"]).indexOf("autoplay=0")!=-1)
      document.getElementById("source")["src"]="https://www.youtube-nocookie.com/embed/"+((this.news.articlesurl).replace("https://youtu.be/",""))+"?controls=0&autoplay=1";
  
  }

  treatMessage(){
    console.log("treatMessage");
    
    if(this.category == "articles"){
      console.log("loader1");
      if(this.news){
        this.loader = true;
        console.log("loader2");

        //this.news["message"] += "<br><br><br>aaaaaa"
        return;
      } else {
        this.router.navigateByUrl('liste/articles');
      }
    }
  }

  treatdesc(message){
    return message.replace(/\n/gi,"<br>");
  }

  
}