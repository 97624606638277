import { Component, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpRequest } from '@angular/common/http';

@Injectable()
export class SearchService {

  private readonly url = 'https://ninou.tech/cooksox/api/search';

  constructor(private http: HttpClient) { }

  search(search): Observable<any>{
    var data ={
      search: search,
    }
    return this.http
      .post( this.url,data );
  }
 
  cut(t,nbr){
    //cut the array to array of array by 5 elements
    var d = [],
    count = (t.length / nbr > Math.floor(t.length / nbr) ) ? Math.floor((t.length / nbr)+1) : Math.floor(t.length / nbr);
    
    for(var i=0; i<count; i++){
      d.push( (t).slice( i*nbr , (i+1)*nbr ) );
    }
    
    var nbrp=d.length;
    var part=((t).length < nbr) ? t : d[0];
    t=d;
    
    return {
      nbrp : nbrp,
      tpart : part,
      t : t
    };

  }
}
