import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ContactService } from './contact.service';
//import swal from 'sweetalert';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit,AfterViewInit {
  public loader : boolean = false;
  public recaptcha_res;
  constructor(private service: ContactService, private titleService: Title) {
    window['verifyCallback'] = this.verifyCallback.bind(this);
   }
 
  ngOnInit() {
    this.titleService.setTitle("CookSox  - Contact");
    window.scrollTo(0,0);
    this.loader = true;
  }

  ngAfterViewInit() {
    this.displayRecaptcha();
  }

  displayRecaptcha(){
    var doc = <HTMLDivElement>document.getElementById('contact-form');
    var script = document.createElement('script');
    script.innerHTML = '';
    script.src = 'https://www.google.com/recaptcha/api.js';
    script.async = true;
    script.defer = true;
    doc.appendChild(script);
  }

  verifyCallback(response){
    //alert(response);
    this.recaptcha_res=response;
  }

  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  send(name,email,phone,sujet,message) {
    /*this.loader = false;
    
    if(!this.validateEmail(email)){
      //swal("Opération échouée", "L'email que vous avez saisi est erroné", "error");
      this.loader = true;
      return;
    }
    
    this.service.send(name,email,phone,sujet,message,this.recaptcha_res).subscribe(
      res => {
            console.log(res)
            if(res.success){
              //swal("Operation réussie", "Votre message a êtes envoyé avec succès nous allons vous répondre dans quelques instants", "success"); 
            } else {
              //swal("Opération échouée", "", "error");
            }
            this.loader = true;
            return;
      },
      err => console.log(err)
    )*/
  } 

}
