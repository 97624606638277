import { Component, OnInit } from '@angular/core';
import { environment } from "../../environments/environment.prod";
import { ListService } from './list.service';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  public loader : boolean = false;
  public btndisable = "";
  public news;
  public news20;
  public category="";
  public i=1;
  public active = [true];

  constructor(private route: ActivatedRoute,private router: Router,private service: ListService, private titleService: Title) { }

  ngOnInit() {
    window.scrollTo(0,0);
    this.route.params.subscribe(params => {
        this.category = params['category'];
        this.titleService.setTitle("BTN - "+this.capitalizeFirstLetter(this.category));

        if(this.category=="articles" || this.category=="videos"){
          this.news = environment[this.category]; 
          this.news20 = (this.service.cut(this.news,20)).t;    
          this.loader = true;
        } else {
          this.router.navigateByUrl("/");
        }
          
    })
  }
   
  ngAfterViewInit(){
    
  }

  addNews(){
    this.btndisable = "disabled";
    this.i++; 
    this.service.getdata(this.category,this.i).subscribe(
      res => {
        environment[this.category] = environment[this.category].concat(res.json());
        this.news = environment[this.category];
        this.btndisable = '';
      }, 
      err => console.log(err)
    )
  }
  
  capitalizeFirstLetter(string) {
      string = string.replace(/-/g, " "); // remove - from category
      return string.charAt(0).toUpperCase() + string.slice(1);
  }

  
}
