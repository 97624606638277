<div >
  <div style="text-align:center" *ngIf="!loader">
      <loader ></loader>
  </div>
  <div *ngIf="loader">
    <div class="row">
      <div class="col xl1 l1 m1 s12"></div>
      <div class="col xl10 l10 m10 s12">
        <div class="card">
          <div class="card-content">
            <div class="card-title">
              Home 
              <span *ngIf="category">
                  > {{capitalizeFirstLetter(category)}}
              </span>
            </div>
          </div>
          <ul class="row" >
            <li class="col s12 m6 l3" *ngFor="let new of news;index as i">
              <article [item]="new" [length]="55" ></article>
            </li>            
          </ul>   
          <div class="row center"> 
            <!--
            <a class="waves-effect waves-light btn blue darken-2 {{btndisable}} " (click)="addNews()">
              afficher plus 
            </a>-->
            <br>
            <br>
            <div class="preloader-wrapper small active" *ngIf="btndisable == 'disabled'">
                <div class="spinner-layer spinner-blue-only">
                  <div class="circle-clipper left">
                    <div class="circle"></div>
                  </div><div class="gap-patch">
                    <div class="circle"></div>
                  </div><div class="circle-clipper right">
                    <div class="circle"></div>
                  </div>
                </div>
            </div>
          </div>

        </div>
      </div> 
      <div class="col xl1 l1 m1 s12"></div>
    </div>
    <br>
    <br>
  </div>
</div>

