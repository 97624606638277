import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { ContactComponent } from './contact/contact.component';
import { ListComponent } from './list/list.component';
import { DetailComponent } from './detail/detail.component';
import { SearchComponent } from './search/search.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { GdprComponent } from './gdpr/gdpr.component';

const routes: Routes = [
    { 
        path: '', 
        component: HomeComponent
    },
    { 
        path: 'liste/:category', 
        component: ListComponent
    },
    { 
        path: ':category/:id/detail', 
        component: DetailComponent
    },
    { 
        path: 'detail/:id/:type', 
        component: DetailComponent
    },
    { 
        path: 'contact', 
        component: ContactComponent
    },
    { 
        path: 'search', 
        component: SearchComponent
    },
    { 
        path: 'search/reload', 
        component: SearchComponent
    },
    { 
        path: 'privacy-policy', 
        component: PrivacyPolicyComponent
    },
    { 
        path: 'terms-and-conditions', 
        component: TermsAndConditionsComponent
    },
    { 
        path: 'GDPR-privacy-notice', 
        component: GdprComponent
    },
    { path: '',
      redirectTo: '/',
      pathMatch: 'full'
    },
    { 
        path: '**', 
        redirectTo: '/'
    }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
