<div >
  <div style="text-align:center" *ngIf="!loader">
      <loader ></loader>
  </div>
  <div *ngIf="loader">
    <br>
    <div class="container">
      <div class="card">
              <div class="card-content">
              <div class="card-title">
                Contact
              </div>
              <div class="row">
                  <p>
                    If you would like more information, please fill out this form. You will be contacted as soon as possible.
                  </p>
              </div>
              <div class="row">
                  <form class="col s12" id="contact-form">
                    <div class="row">
                      <div class="input-field col s12">
                        <input #name id="nom" type="text" class="validate">
                        <label for="nom" class="active">Full Name</label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="input-field col s12">
                        <input #email id="email" type="email" class="validate">
                        <label for="email" class="active">Email</label>
                      </div>
                    </div>
                    <div class="row">
                      <div class="input-field col s12">
                        <input #phone id="phone" type="text" class="validate">
                        <label for="phone" class="active">Phone</label>
                      </div>
                    </div>
                    <div class="row">
                        <div class="input-field col s12">
                          <input #subject id="subject" type="text" class="validate">
                          <label for="subject" class="active">Subject</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="input-field col s12">
                          <textarea #message id="message" class="materialize-textarea"></textarea>
                          <label for="message" class="active">Message</label>
                        </div>
                    </div>
                    <!--<div class="g-recaptcha" data-callback="verifyCallback" data-sitekey="6LdREGkUAAAAABUJSbDnnskM_Hn7IPCfq7i0KOG2"></div>  -->                    
                  </form>
                </div>
                
          </div>
          <div class="card-action center">
              <a class="waves-effect waves-light btn blue darken-3" (click)="send(name.value,email.value,phone.value,subject.value,message.value)">Submit</a>
          </div>
      </div>
    </div>
    <br>
    <br>
  </div>
</div>

