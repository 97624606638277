import { Component, OnInit } from '@angular/core';
import { HomeService } from './home.service';
import $ from 'jquery';
import Materialize from 'Materialize-css';
import { environment } from "../../environments/environment.prod";
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {
  public loader : boolean = false;
  public finish : boolean = false;
  public i = 1;
  public news;
  public language;
  public btndisable = "";
  constructor(private service: HomeService, private titleService: Title) { }
   
  ngOnInit() {  
    this.titleService.setTitle("BTN");
    window.scrollTo(0,0);
    console.log(environment); 
    this.news = environment["allnews"];
    this.loader = true;
    this.service.getdata(this.i).subscribe(
      res => {
        environment["allnews"]=res;
        environment["articles"]=res;
        this.news = environment["allnews"];
        console.log(res)
        this.loader = true;  
      },
      err => console.log(err)
    )
  }
 
  ngAfterViewInit(){
    
  }

  addNews(){
    this.btndisable = "disabled";
    this.i++; 
    this.service.getdata(this.i).subscribe(
      res => {
        environment["allnews"] = environment["allnews"].concat(res.json());
        this.news = environment["allnews"];
        this.btndisable = '';
      }, 
      err => console.log(err)
    )
  }
}
