<div >
    <div style="text-align:center" *ngIf="!loader">
            <loader ></loader>
    </div>
    <div *ngIf="loader">
      <div class="row">
        <div class="col xl1  l1  m12 s12"></div> 
        <div class="col xl10 l10 m12 s12 detail"  dir="auto">
                  <!-- product detail -->
                  <div class="card">
                    <div class="card-image" *ngIf="category == 'articles'">
                                    <img src="{{news.url}}" > 
                    </div>  
                    <br>
                    <div class="card-title title" >
                        {{news.title}}
                    </div>
                    <div class="sub-title title blue-text">
                        {{news.date}}
                    </div>
                    
                    <div class="card-content">
                        <div class="row">
                            <div class="col s12"  *ngIf="category == 'articles'">
                                <div [innerHTML]="news.message"></div>
                                <!--<a (click)='gotoSource()' style="cursor: pointer">
                                    <span *ngIf="news.language == 'ar'">المصدر</span>
                                    <span *ngIf="news.language == 'fr'">La source</span>
                                </a>-->
                            </div>
                            <!--<video class="col s12 responsive-video" controls *ngIf="category == 'videos'">
                                    <source src="https://www.youtube.com/embed/{{news.id}}" type="video/mp4">
                            </video>-->
                            
                            <div class="video-container"  *ngIf="category == 'videos' && news.id">
                                <div (click)="clickVideo()" class="video-container" style="
                                    background-color: #ff000000;
                                    width: 853px;
                                    height: 480px;
                                    z-index: 1;
                                    position: absolute;
                                "></div>
                                <iframe width="853" height="480" frameborder="0" id="source"></iframe>
                            </div>
                        </div>
                        

                        <div class="row center"  dir="ltr">    
                                <div class="addthis_inline_share_toolbox"></div>
                        </div>
                    </div>
                  </div>                 
        </div> 
        
        <div class="col xl1 l1 m12 s12"></div> 
         
      </div>
      <br>
      <br>
    </div>
  </div>
  
  