import { Component, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpRequest } from '@angular/common/http';

@Injectable()
export class DetailService {
  private readonly url  =  'https://ninou.tech/cooksox/api/';
  
  constructor(private http: HttpClient) { }
  
  select(category,id): Observable<any>{

    return this.http
      .get( this.url+category+"/"+id );
      
  }

  cut(t,nbr){
    //cut the array to array of array by 5 elements
    var d = [],
    count = (t.length / nbr > Math.floor(t.length / nbr) ) ? Math.floor((t.length / nbr)+1) : Math.floor(t.length / nbr);
    
    for(var i=0; i<count; i++){
      d.push( (t).slice( i*nbr , (i+1)*nbr ) );
    }
    
    var nbrp=d.length;
    var part=((t).length < nbr) ? t : d[0];
    t=d;
    
    return {
      nbrp : nbrp,
      tpart : part,
      t : t
    };

  }

  
}
