<footer class="page-footer blue darken-1">
<br>
    <div class="row center">
            <h5>
                    We are present on social networks
            </h5>
    </div>
    <div class="row center">
        <a href="https://facebook.com" target="_blank">
            <img src="assets/facebook.png" alt="facebook">
        </a>  
        <a href="https://m.me" target="_blank">
            <img src="assets/messanger.png" alt="messanger">
        </a>  
        <a href="https://twitter.com" target="_blank">
            <img src="assets/twitter.png" alt="twitter">
        </a>    
        <a href="https://plus.google.com" target="_blank">
            <img src="assets/googleplus.png" alt="googleplus">
        </a>  
              
    </div>

    <div class="footer-copyright">
      <div class="container">
          © CookSox  - All Rights Reserved
      </div>
    </div>
</footer>