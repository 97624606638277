import { Component, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpRequest } from '@angular/common/http';
 
@Injectable()
export class ContactService {

  private readonly _url = 'https://ninou.tech/cooksox/api/contact/send';
  
  constructor(private http: HttpClient) { 
  }

  send(name,email,phone,sujet,message,recaptcha_res): Observable<any>{
    var data = {
      name : name,
      email : email,
      phone : phone,
      sujet : sujet,
      recaptcha_res : recaptcha_res,
      message : message
    };
    return this.http
      .post( this._url, data );
  }

}
